$slick-icon-width-min-size: 8;
$slick-icon-width-max-size: 50;

@-webkit-keyframes md-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes md-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@mixin md-icon-rotate($degrees, $rotation) {
  transform: rotate($degrees);
}

.mdi {
  &.mdi-v-align-bottom:before {
    vertical-align: bottom;
  }
  &.mdi-v-align-middle:before {
    vertical-align: middle;
  }
  &.mdi-v-align-sub:before {
    vertical-align: sub;
  }
  &.mdi-v-align-super:before {
    vertical-align: super;
  }
  &.mdi-v-align-text-bottom:before {
    vertical-align: text-bottom;
  }
  &.mdi-v-align-text-top:before {
    vertical-align: text-top;
  }
  &.mdi-v-align-top:before {
    vertical-align: top;
  }

  &.mdi-flip-h {
    transform: scaleX(-1);
  }
  &.mdi-flip-v {
    transform: scaleY(-1);
  }
  &.mdi-pulse {
    animation: md-spin 1s infinite steps(8);
  }
  /* use mdi-spin or mdi-spin-1s to change the speed */
  &.mdi-spin {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    animation: md-spin 2s infinite linear;
  }
  @for $i from 1 through 5 {
    &.mdi-spin-#{$i}s {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      animation: md-spin #{$i}s infinite linear;
    }
  }
  &.mdi-rotate-45  { @include md-icon-rotate(45deg, 1);  }
  &.mdi-rotate-90  { @include md-icon-rotate(90deg, 1);  }
  &.mdi-rotate-135 { @include md-icon-rotate(135deg, 2); }
  &.mdi-rotate-180 { @include md-icon-rotate(180deg, 2); }
  &.mdi-rotate-220 { @include md-icon-rotate(220deg, 3); }
  &.mdi-rotate-270 { @include md-icon-rotate(270deg, 3); }
  &.mdi-rotate-315 { @include md-icon-rotate(315deg, 3); }
  &.mdi-rotate-45, .mdi-rotate-90, .mdi-rotate-135, .mdi-rotate-180, .mdi-rotate-220 .mdi-rotate-270, .mdi-rotate-315 {
    filter: none;
  }

  @for $i from $slick-icon-width-min-size through $slick-icon-width-max-size {
    &.mdi-#{$i}px {
      &:before {
        height: #{$i}px;
        width: #{$i}px;
      }
    }
  }
}
