@use './private';

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width : 768px) {
  @for $i from 1 through 12 {
    $width: private.private-div($i, 12) * 100%;
    .slick-col-medium-#{$i} {
      flex-basis: $width;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width : 992px) {
  @for $i from 1 through 12 {
    $width: private.private-div($i, 12) * 100%;
    .slick-col-large-#{$i} {
      flex-basis: $width;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  @for $i from 1 through 12 {
    $width: private.private-div($i, 12) * 100%;
    .slick-col-xlarge-#{$i} {
      flex-basis: $width;
    }
  }
}

/* Response Design for mobile phones (100% width) */
@media only screen and (max-width: 768px) {
  [class*="slick-col-"] {
    width: 100%;
  }
}
