/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import "~nouislider/dist/nouislider.min.css";
button:focus {outline:0;}

body {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: normal;
    line-height: 24px;
    margin-bottom: 0;
    font-family: Metropolis,"Avenir Next","Helvetica Neue",Arial,sans-serif;
    margin-top: 0 !important;
    overflow: -moz-scrollbars-vertical;
    overflow-x: hidden;
    overflow-y: auto;
}

/* For nouislider */
.noUi-horizontal {
    height: 4px !important;
}

.noUi-handle {
    border: none !important;
    box-shadow: none !important;
    height: 18px !important;
    width: 18px !important;
    border-radius: 50% !important;
    background: #0094d2 !important;
    color: #0094d2 !important;
    top: -7px !important;
    margin-right: 2em !important;
}

.noUi-base, .noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    margin-left: 20px;
}

.noUi-handle:before {
    background: none !important;
}

.noUi-handle:after {
    background: none !important;
}

.noUi-target {
    top: 2px;
    border: none !important;
    box-shadow: none !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    background: #0094d2 !important;
}

.main-container .content-container .content-area {
    overflow-y: unset !important;
    padding: 1.2rem 1.2rem 0.75rem 1.2rem;
    padding-right: 0rem;
}

.main-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    background: #fafafa;
    position: fixed;
    width: 100%;
}

.child-container {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: 100%;
}

/* For data grid styling */
.fl-table {
    height: calc(100% - 90px);
    padding-right: 24px;
}

.pad-l24 {
    padding-left: 24px;
}

.pad-r24 {
    padding-right: 24px;
}

.open-hamburger-menu {
    z-index:1000;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.body-container::-webkit-scrollbar {
  display: none;
}

.sidenav-content {
  min-width: 216px;
}


header.header-6, .header.header-6 {
    background-color: #002538;
}

.main-container header, .main-container .header {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    position:fixed;
}
header, .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #fafafa;
    height: 60px;
    white-space: nowrap;
}
header, .header {
    background-color: #313131;
}
*, *::before, *::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.sidenav {
    line-height: 1.2rem;
    max-width: 18.6rem;
    width: 18%;
    min-width: 18.6em;
    border-right: .05rem solid #ccc;
    display: flex;
    flex-direction: column;
}

.toast-success {
  background-color: #007CBB;
}


