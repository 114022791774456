@import './variables';

$slick-form-control-border: 1px solid #ccc !default;
$slick-form-control-focus-border-color: lighten($slick-primary-color, 10%) !default;
$slick-form-control-focus-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px rgba(lighten($slick-primary-color, 3%), .3) !default;

/** Bootstrap buttons styling copied and changed slightly for Material Design */
.slick-editor-modal, .slick-large-editor-text {
  .btn {
    cursor: pointer;
    font-family: var(--slick-font-family, $slick-font-family);
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .btn-default {
    color: var(--slick-button-primary-color, $slick-button-primary-color);
    background-color: #fff;
    border-color: #ccc;
  }

  .btn-primary {
    color: #fff;
    background-color: var(--slick-button-primary-bg-color, $slick-button-primary-bg-color);
  }

  .btn-xs,
  .btn-group-xs>.btn {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
  .btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
}

.gridPane, .grid-pane, .slick-editor-modal {
  font-family: var(--slick-font-family, $slick-font-family);

  .form-control {
    display: block;
    width: 100%;
    font-size: var(--slick-font-size-base, $slick-font-size-base);
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }

  .input-group .form-control {
    border-radius: 4px;
  }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
  }
  .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
  }
  .input-group-addon {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .input-group-addon, .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
  }
  .input-group .form-control, .input-group-addon, .input-group-btn {
    display: table-cell;
  }
  .input-group-addon:last-child {
    border-left: 0;
  }
  .input-group .form-control:last-child,
  .input-group-addon:last-child,
  .input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
  .input-group-btn:first-child>.btn:not(:first-child),
  .input-group-btn:last-child>.btn,
  .input-group-btn:last-child>.btn-group>.btn,
  .input-group-btn:last-child>.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group .form-control:first-child,
  .input-group-addon:first-child,
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .btn-group > .btn,
  .input-group-btn:first-child > .dropdown-toggle,
  .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
    z-index: 2;
    margin-left: -1px;
  }

  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  *, :after, :before {
    box-sizing: border-box;
  }

  .form-control:focus {
    outline: 0;
    border-color: var(--slick-form-control-focus-border-color, $slick-form-control-focus-border-color);
    box-shadow: var(--slick-form-control-focus-box-shadow, $slick-form-control-focus-box-shadow);
  }

  .slick-pagination {
    .slick-pagination-nav {
      .pagination > li {
        a, span {
          position: relative;
          float: left;
          margin-left: -1px;
        }
      }
      .pagination > .disabled > a,
      .pagination > .disabled > a:focus,
      .pagination > .disabled > a:hover,
      .pagination > .disabled > span,
      .pagination > .disabled > span:focus,
      .pagination > .disabled > span:hover {
        cursor: not-allowed;
      }
    }
  }
}
