/** Salesforce Theme (includes all external lib styling) */
@import './sass-utilities';

/**
 * This is a bare version of "slickgrid-theme-salesforce.scss",
 * Few files were removed and aren't included in this styling theme (while they are in original theme)
 * - (colors, extra-styling, material-svg-icons, material-svg-utilities, slick-without-bootstrap-min-styling)
 */

$slick-primary-color:                                         #006DCC !default;
$slick-primary-color-dark:                                    #004487 !default;
$slick-highlight-color:                                       #0070D2 !default;
$slick-button-primary-bg-color:                               $slick-primary-color-dark !default;
$slick-button-primary-color:                                  $slick-primary-color-dark !default;
$slick-bare-color:                                            #b5b5b5 !default;
$slick-icon-color:                                            #514f4d !default;
$slick-font-family:                                           var(--lwc-fontFamily,'Salesforce Sans', 'SalesforceSans-Regular', Arial, sans-serif) !default;
$slick-cell-active-box-shadow:                                inset 0 0 0 1px #{$slick-highlight-color} !default;
$slick-cell-border-top:                                       none !default;
$slick-cell-border-bottom:                                    none !default;
$slick-cell-box-shadow:                                       0px 1px 0px #dddbda, 0px -1px 0px #dddbda !default;
$slick-cell-font-weight:                                      400 !default;
$slick-cell-odd-background-color:                             #f3f2f2 !default;
$slick-cell-odd-background-color:                             #ffffff !default;
$slick-cell-text-color:                                       #080707 !default;
$slick-font-size-base-value:                                  13 !default;
$slick-icon-font-size:                                        18px !default;
$slick-icon-width:                                            18px !default;
$slick-frozen-border-bottom:                                  1px solid #{$slick-highlight-color} !default;
$slick-frozen-border-right:                                   1px solid #{$slick-highlight-color} !default;
$slick-group-totals-formatter-color:                          #666666 !default;
$slick-header-background-color:                               #fafaf9 !default;
$slick-header-border-bottom:                                  transparent !default;
$slick-header-font-size:                                      13px !default;
$slick-header-font-weight:                                    700 !default;
$slick-header-text-color:                                     #514f4d !default;
$slick-header-border-bottom:                                  transparent !default;
$slick-header-filter-row-border-bottom:                       2px solid #{$slick-primary-color-dark} !default;
$slick-pane-top-border-top:                                   1px solid #d0d0d0 !default;
$slick-header-resizable-hover:                                2px solid #{$slick-highlight-color} !default;
$slick-header-resizable-hover-border-bottom:                  $slick-header-resizable-hover !default;
$slick-header-resizable-hover-border-left:                    $slick-header-resizable-hover !default;
$slick-header-resizable-hover-border-right:                   $slick-header-resizable-hover !default;
$slick-header-resizable-hover-border-top:                     $slick-header-resizable-hover !default;
$slick-header-resizable-hover-width:                          4px !default;
$slick-header-resizable-hover-border-radius:                  8px !default;
$slick-header-resizable-hover-right:                          0 !default;
$slick-header-resizable-hover-opacity:                        0.5 !default;
$slick-container-border-top:                                  1px solid #ccc !default;
$slick-container-border-bottom:                               1px solid #ccc !default;
$slick-container-border-left:                                 0px solid #ccc !default;
$slick-container-border-right:                                0px solid #ccc !default;
$slick-icon-sort-color:                                       $slick-highlight-color !default;
$slick-icon-sort-asc:                                         url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-icon-sort-color)}" viewBox="0 0 24 24"><path d="M19.1 9.7c.4-.4.4-.9 0-1.3l-6.9-6.7c-.4-.4-.9-.4-1.3 0L4 8.4c-.4.4-.4.9 0 1.3l1.3 1.2c.3.4.9.4 1.3 0l2.1-2.1c.4-.4 1-.1 1 .4v12.5c0 .5.5.9 1 .9h1.8c.5 0 .9-.5.9-.9V9.2c0-.5.7-.8 1-.4l2.2 2.1c.4.4.9.4 1.3 0l1.2-1.2z"></path></svg>') !default;
$slick-icon-sort-desc:                                        url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-icon-sort-color)}" viewBox="0 0 24 24"><path d="M4.4 14.3c-.3.4-.3.9 0 1.3l7 6.7c.3.4.9.4 1.2 0l7-6.7c.4-.4.4-.9 0-1.3l-1.3-1.2c-.3-.4-.9-.4-1.3 0l-2.1 2.1c-.4.4-1.1.1-1.1-.4V2.3c0-.5-.4-.9-.9-.9h-1.8c-.5 0-.9.5-.9.9v12.5c0 .5-.7.8-1.1.4L7 13.1c-.4-.4-1-.4-1.3 0l-1.3 1.2z"></path></svg>') !default;
$slick-icon-sort-font-size:                                   13px !default;
$slick-icon-sort-width:                                       13px !default;
$slick-icon-sort-position-right:                              12px !default;
$slick-checkbox-selector-checked-color:                       $slick-highlight-color !default;
$slick-checkbox-selector-unchecked-color:                     $slick-bare-color !default;
$slick-checkbox-selector-size:                                12px !default;
$slick-checkbox-selector-icon-font-weight:                    normal !default;
$slick-checkbox-selector-icon-height:                         calc(#{$slick-checkbox-selector-size} + 4px) !default;
$slick-checkbox-selector-icon-width:                          calc(#{$slick-checkbox-selector-size} + 4px) !default;
$slick-checkbox-selector-icon-margin:                         2px 0 0 0 !default;
$slick-checkbox-selector-icon-border:                         1px solid #d6d4d4 !default;
$slick-checkbox-selector-icon-border-radius:                  0.125rem !default;
$slick-checkbox-selector-icon-bg-color:                       white !default;
$slick-checkbox-selector-color:                               $slick-highlight-color !default;
$slick-checkbox-selector-icon-checked:                        url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-checkbox-selector-color)}" viewBox="0 0 24 24"><path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"></path></svg>') !default;
$slick-checkbox-selector-icon-unchecked:                      url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="transparent" viewBox="0 0 24 24"><path d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"></path></svg>') !default;
$slick-checkbox-selector-opacity:                             1 !default;
$slick-checkbox-selector-opacity-hover:                       0.9 !default;
$slick-column-picker-checkbox-color:                          $slick-primary-color !default;
$slick-column-picker-checkbox-size:                           $slick-icon-width !default;
$slick-column-picker-checkbox-font-weight:                    normal !default;
$slick-column-picker-checkbox-icon-checked:                   url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-column-picker-checkbox-color)}" viewBox="0 0 24 24"><path d="M8.8 19.6L1.2 12c-.3-.3-.3-.8 0-1.1l1-1c.3-.3.8-.3 1 0L9 15.7c.1.2.5.2.6 0L20.9 4.4c.2-.3.7-.3 1 0l1 1c.3.3.3.7 0 1L9.8 19.6c-.2.3-.7.3-1 0z"></path></svg>') !default;
$slick-column-picker-checkbox-icon-unchecked:                 $slick-column-picker-checkbox-icon-checked !default;
$slick-column-picker-checkbox-width:                          13px !default;
$slick-column-picker-close-btn-font-size:                     23px !default;
$slick-column-picker-item-font-size:                          14px !default;
$slick-column-picker-checkbox-icon-line-height:               16px !default;
$slick-column-picker-checkbox-icon-vertical-align:            middle !default;
$slick-column-picker-item-height:                             26px !default;
$slick-column-picker-item-line-height:                        16px !default;
$slick-column-picker-title-font-size:                         17px !default;
$slick-menu-item-font-size:                                   14px !default;
$slick-menu-item-height:                                      26px !default;
$slick-menu-icon-line-height:                                 18px !default;
$slick-menu-icon-width:                                       18px !default;
$slick-menu-title-font-size:                                  17px !default;
$slick-icon-group-color:                                      $slick-primary-color !default;
$slick-icon-group-collapsed:                                  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-icon-group-color)}" viewBox="0 0 24 24"><path d="M8.3 2l9.5 9.5c.3.3.3.7 0 1L8.3 22c-.3.2-.8.2-1.1 0l-1-1c-.2-.3-.2-.8 0-1.1l7.6-7.4c.2-.3.2-.7 0-1L6.3 4.1C6 3.8 6 3.3 6.3 3l1-1c.3-.2.7-.2 1 0z"></path></svg>') !default;
$slick-icon-group-expanded:                                   url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-icon-group-color)}" viewBox="0 0 24 24"><path d="M22 8.2l-9.5 9.6c-.3.2-.7.2-1 0L2 8.2c-.2-.3-.2-.7 0-1l1-1c.3-.3.8-.3 1.1 0l7.4 7.5c.3.3.7.3 1 0l7.4-7.5c.3-.2.8-.2 1.1 0l1 1c.2.3.2.7 0 1z"></path></svg>') !default;
$slick-icon-group-font-weight:                                normal !default;
$slick-icon-group-height:                                     auto !default;
$slick-icon-group-vertical-align:                             bottom !default;
$slick-icon-group-width:                                      15px !default;
$slick-filled-filter-color:                                   $slick-primary-color-dark !default;
$slick-filled-filter-border:                                  1px solid #{$slick-primary-color} !default;
$slick-filled-filter-box-shadow:                              inset 0 0 0 1px #{lighten($slick-primary-color, 30%)} !default;
$slick-filled-filter-font-weight:                             bold !default;
$slick-draggable-group-drop-border-top:                       0px !default;
$slick-draggable-group-drop-width:                            100% !default;
$slick-draggable-group-drop-radius:                           0px !default;
$slick-draggable-group-delete-vertical-align:                 middle !default;
$slick-draggable-group-toggle-all-icon-height:                $slick-icon-font-size !default;
$slick-draggable-group-toggle-all-icon-width:                 $slick-icon-font-size !default;
$slick-draggable-group-toggle-all-icon-vertical-align:        text-bottom !default;
$slick-draggable-group-toggle-collapsed-icon:                 $slick-icon-group-collapsed !default;
$slick-draggable-group-toggle-expanded-icon:                  $slick-icon-group-expanded !default;
$slick-draggable-group-title-height:                          24px !default;
$slick-draggable-group-title-line-height:                     24px !default;
$slick-draggable-group-title-vertical-align:                  top !default;
$slick-header-button-hidden-margin-right:                     -6px !default;
$slick-header-button-height:                                  18px !default;
$slick-header-button-width:                                   18px !default;
$slick-header-button-margin:                                  -4px 0 100px 0 !default;
$slick-header-menu-button-height:                             25px !default;
$slick-header-menu-button-icon-font-size:                     26px !default;
$slick-header-menu-button-icon-color:                         #706e6b !default;
$slick-header-menu-button-icon:                               url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-header-menu-button-icon-color)}" viewBox="0 0 24 24"><path d="M22 8.2l-9.5 9.6c-.3.2-.7.2-1 0L2 8.2c-.2-.3-.2-.7 0-1l1-1c.3-.3.8-.3 1.1 0l7.4 7.5c.3.3.7.3 1 0l7.4-7.5c.3-.2.8-.2 1.1 0l1 1c.2.3.2.7 0 1z"></path></svg>') !default;
$slick-header-menu-button-margin-right:                       8px !default;
$slick-autocomplete-loading-icon-color:                       $slick-icon-color !default;
$slick-autocomplete-loading-icon:                             url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-autocomplete-loading-icon-color)}" viewBox="0 0 24 24"><path d="M16,13V11H21V13H16M14.83,7.76L17.66,4.93L19.07,6.34L16.24,9.17L14.83,7.76M11,16H13V21H11V16M11,3H13V8H11V3M4.93,17.66L7.76,14.83L9.17,16.24L6.34,19.07L4.93,17.66M4.93,6.34L6.34,4.93L9.17,7.76L7.76,9.17L4.93,6.34M8,13H3V11H8V13M19.07,17.66L17.66,19.07L14.83,16.24L16.24,14.83L19.07,17.66Z"></path></svg>') !default;
$slick-autocomplete-loading-icon-width:                       22px !default;
$slick-autocomplete-loading-icon-margin:                      2px 0 0 -26px !default;
$slick-autocomplete-loading-icon-line-height:                 0px !default;
$slick-autocomplete-loading-icon-vertical-align:              sub !default;
$slick-compound-filter-operator-select-border:                1px solid #6cb6ff !default;
$slick-compound-filter-text-color:                            $slick-primary-color-dark !default;
$slick-multiselect-icon-arrow-font-size:                      calc(#{$slick-icon-font-size} - 4px) !default;
$slick-multiselect-icon-checked-color:                        $slick-highlight-color !default;
$slick-multiselect-icon-border:                               1px solid #d6d4d4 !default;
$slick-multiselect-icon-height:                               15px !default;
$slick-multiselect-icon-width:                                15px !default;
$slick-multiselect-icon-margin:                               0px 4px 0 0 !default;
$slick-multiselect-icon-border-radius:                        0.125rem !default;
$slick-multiselect-icon-unchecked-color:                      $slick-bare-color !default;
$slick-multiselect-icon-radio-color:                          $slick-highlight-color !default;
$slick-multiselect-icon-checked:                              url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-multiselect-icon-checked-color)}" viewBox="0 0 24 24"><path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"></path></svg>') !default;
$slick-multiselect-icon-unchecked:                            url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="transparent" viewBox="0 0 24 24"><path d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"></path></svg>') !default;
$slick-multiselect-icon-radio-checked:                        url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-multiselect-icon-radio-color)}" viewBox="0 0 24 24"><path d="M12 3.7c4.6 0 8.3 3.7 8.3 8.3s-3.7 8.3-8.3 8.3-8.3-3.7-8.3-8.3S7.4 3.7 12 3.7z"></path></svg>') !default;
$slick-multiselect-icon-radio-unchecked:                      url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="transparent" viewBox="0 0 24 24"><path d="M12 3.7c4.6 0 8.3 3.7 8.3 8.3s-3.7 8.3-8.3 8.3-8.3-3.7-8.3-8.3S7.4 3.7 12 3.7z"></path></svg>') !default;
$slick-multiselect-icon-font-size:                            10px !default;
$slick-multiselect-icon-radio-border-radius:                  50% !default;
$slick-multiselect-unchecked-opacity:                         0.8 !default;
$slick-preheader-border-right:                                1px solid #e8e8e8 !default;
$slick-preheader-grouped-title-justify:                       center !default;
$slick-preheader-font-size:                                   14px !default;
$slick-preheader-height:                                      21px !default;
$slick-row-move-plugin-icon-color:                            $slick-icon-color !default;
$slick-row-move-plugin-cursor:                                grab !default;
$slick-row-move-plugin-icon-width:                            18px !default;
$slick-row-move-plugin-icon-vertical-align:                   -webkit-baseline-middle !default;
$slick-row-move-plugin-icon:                                  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-row-move-plugin-icon-color)}" viewBox="0 0 24 24"><path d="M11,18 C11,19.1 10.1,20 9,20 C7.9,20 7,19.1 7,18 C7,16.9 7.9,16 9,16 C10.1,16 11,16.9 11,18 Z M9,10 C7.9,10 7,10.9 7,12 C7,13.1 7.9,14 9,14 C10.1,14 11,13.1 11,12 C11,10.9 10.1,10 9,10 Z M9,4 C7.9,4 7,4.9 7,6 C7,7.1 7.9,8 9,8 C10.1,8 11,7.1 11,6 C11,4.9 10.1,4 9,4 Z M15,8 C16.1,8 17,7.1 17,6 C17,4.9 16.1,4 15,4 C13.9,4 13,4.9 13,6 C13,7.1 13.9,8 15,8 Z M15,10 C13.9,10 13,10.9 13,12 C13,13.1 13.9,14 15,14 C16.1,14 17,13.1 17,12 C17,10.9 16.1,10 15,10 Z M15,16 C13.9,16 13,16.9 13,18 C13,19.1 13.9,20 15,20 C16.1,20 17,19.1 17,18 C17,16.9 16.1,16 15,16 Z"></path></svg>') !default;
$slick-editor-input-height:                                   100% !default;
$slick-editor-input-group-clear-btn-icon-color:               $slick-icon-color !default;
$slick-editor-input-group-clear-btn-icon:                     url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-editor-input-group-clear-btn-icon-color)}" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>') !default;
$slick-editor-input-group-clear-btn-icon-margin-top:          inherit !default;
$slick-editor-input-group-clear-btn-icon-padding:             1px 6px !default;
$slick-editor-input-group-clear-btn-icon-size:                calc(#{$slick-icon-font-size} + 2px) !default;
$slick-editor-input-group-clear-btn-icon-vertical-align:      initial !default;
$slick-editor-input-group-clear-btn-icon-height:              14px !default;
$slick-editor-input-group-clear-btn-icon-width:               14px !default;
$slick-editor-focus-box-shadow:                               0 0 3px $slick-primary-color !default;
$slick-editor-modal-container-radius:                         var(--lwc-borderRadiusMedium, 0.25rem) !default;
$slick-editor-modal-close-btn-outside-color:                  #ffffff !default;
$slick-editor-modal-close-btn-outside-font-size:              36px !default;
$slick-editor-modal-close-btn-outside-right:                  -7px !default;
$slick-editor-modal-close-btn-outside-top:                    -32px !default;
$slick-editor-grid-cell-border-width-modified:                1px 7px 1px 1px !default;
$slick-editor-modal-detail-container-border-width-modified:   1px 10px 1px 1px !default;
$slick-editor-modal-footer-btn-height:                        28px;
$slick-editor-modal-header-border-bottom:                     var(--lwc-borderWidthThick, 2px) solid var(--lwc-colorBorder, rgb(221, 219, 218)) !default;
$slick-editor-modal-title-font-size:                          var(--lwc-fontSize7, 1.25rem) !default;
$slick-editor-modal-title-font-weight:                        var(--lwc-fontWeightLight, 300) !default;
$slick-editor-modal-title-line-height:                        var(--lwc-lineHeightHeading, 1.25) !default;
$slick-editor-modal-title-text-align:                         center !default;
$slick-large-editor-button-border-radius:                     3px !default;
$slick-slider-filter-thumb-color:                             #3C97DD !default;
$slick-slider-filter-runnable-track-bgcolor:                  #ECEBEA !default;
$slick-row-selected-color:                                    #ECEBEA !default;
$slick-row-highlight-background-color:                        lighten($slick-highlight-color, 50%) !default;
$slick-row-mouse-hover-color:                                 #f3f2f2 !default;
$slick-row-mouse-hover-box-shadow:                            0 0 0 2px #dddbda !default;
$slick-detail-view-icon-collapse-color:                       $slick-primary-color !default;
$slick-detail-view-icon-expand-color:                         $slick-primary-color !default;
$slick-detail-view-icon-collapse:                             url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-detail-view-icon-collapse-color)}" viewBox="0 0 24 24"><path d="M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"></path></svg>') !default;
$slick-detail-view-icon-expand:                               url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-detail-view-icon-expand-color)}" viewBox="0 0 24 24"><path d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"></path></svg>') !default;
$slick-pagination-icon-color:                                 $slick-primary-color !default;
$slick-pagination-icon-height:                                $slick-icon-width !default;
$slick-pagination-icon-seek-first:                            url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-pagination-icon-color)}" viewBox="0 0 24 24"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"/><path d="M24 24H0V0h24v24z" fill="none"/></svg>') !default;
$slick-pagination-icon-seek-end:                              url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-pagination-icon-color)}" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"/></svg>') !default;
$slick-pagination-icon-seek-next:                             url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-pagination-icon-color)}" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path></svg>') !default;
$slick-pagination-icon-seek-prev:                             url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{encodecolor($slick-pagination-icon-color)}" viewBox="0 0 24 24"> <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg>') !default;
$slick-pagination-icon-seek-first-width:                      $slick-icon-width !default;
$slick-pagination-icon-seek-prev-width:                       $slick-icon-width !default;
$slick-pagination-icon-seek-next-width:                       $slick-icon-width !default;
$slick-pagination-icon-seek-end-width:                        $slick-icon-width !default;
$slick-pagination-button-padding:                             6px 9px !default;
$slick-pagination-button-border-radius:                       2px !default;
$slick-pagination-page-input-border-radius:                   3px !default;
