@use './private';
$svg-icon-vertical-align: bottom !default;

@function encodecolor($string) {
	@if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string:unquote("#{$hex}");
    }
    $string: '%23' + $string;
	@return $string;
}

@mixin recolor($color: #000, $opacity: 1) {
  $r: private.private-div(red($color), 255);
  $g: private.private-div(green($color), 255);
  $b: private.private-div(blue($color), 255);
  $a: $opacity;

  // grayscale fallback if SVG from data url is not supported
  $lightness: lightness($color);
  filter: saturate(0%) brightness(0%) invert($lightness) opacity($opacity);

  // color filter
  $svg-filter-id: "recolor";
  filter: url('data:image/svg+xml;utf8,\
    <svg xmlns="http://www.w3.org/2000/svg">\
      <filter id="#{$svg-filter-id}" color-interpolation-filters="sRGB">\
        <feColorMatrix type="matrix" values="\
          0 0 0 0 #{$r}\
          0 0 0 0 #{$g}\
          0 0 0 0 #{$b}\
          0 0 0 #{$a} 0\
        "/>\
      </filter>\
    </svg>\
    ##{$svg-filter-id}');
}

@mixin loadsvg($icon-class, $path-drawing, $fill, $height: 18px, $width: 18px, $display: inline-block, $viewBox: 24) {
  #{$icon-class}:before {
    height: $height;
    width: $width;
    display: $display;
    vertical-align: $svg-icon-vertical-align;
    // margin-top: -1px; // small patch to remove padding all around the SVG
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#{$fill}" viewBox="0 0 #{$viewBox} #{$viewBox}"><path d="#{$path-drawing}"></path></svg>');
  }
}
