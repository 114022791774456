@import './sass-utilities';

/* icon/text colors */
// we'll use the same color set as text-colors defined in Bootstrap 4 (ref: https://getbootstrap.com/docs/4.5/utilities/colors/)
// we also use CSS Filter to color on top of SVG icons, we use this codepen to calculate the Filter Color (https://codepen.io/sosuke/pen/Pjoqqp)
// since our icon are not pure black, we also need to prepend "brightness(0) saturate(100%)" to the filter
// NOTE: remember that we are using CSS "filter" and that is NOT the same as a using regular color/background-color, it behaves differently

$color-primary: $slick-primary-color !default;
$color-secondary: #6c757d !default;
$color-success: #28a745 !default;
$color-danger: #dc3545 !default;
$color-warning: #ffc107 !default;
$color-info: #17a2b8 !default;
$color-light: #f8f9fa !default;
$color-dark: #343a40 !default;
$color-body: #212529 !default;
$color-muted: #6c757d !default;
$color-white: #ffffff !default;
$color-disabled: #DDDBDA !default;
$color-disabled-dark: #cccccc !default;
$color-alt-default: #1e87f0 !default;
$color-alt-warning: #faa05a !default;
$color-alt-danger: #f0506e !default;
$color-alt-success: #32d296 !default;
$color-se-primary: #3dcd58 !default;
$color-se-link: #42b4e6 !default;
$color-se-link-dark: #337ab7 !default;
$color-se-danger: #b10043 !default;
$color-se-secondary: #9fa0a4 !default;
$color-se-warning: #e47f00 !default;
$color-se-warning-light: #ffd100 !default;
$color-sf-highlight: #0070D2 !default;
$color-sf-primary: #006DCC !default;
$color-sf-primary-dark: #004487 !default;

.color-primary { filter: brightness(0) saturate(100%) invert(31%) sepia(57%) saturate(6822%) hue-rotate(194deg) brightness(93%) contrast(102%); }
.color-secondary { filter: brightness(0) saturate(100%) invert(49%) sepia(14%) saturate(290%) hue-rotate(167deg) brightness(89%) contrast(89%); }
.color-success { filter: brightness(0) saturate(100%) invert(46%) sepia(96%) saturate(377%) hue-rotate(81deg) brightness(94%) contrast(91%); }
.color-danger { filter: brightness(0) saturate(100%) invert(35%) sepia(56%) saturate(4390%) hue-rotate(334deg) brightness(88%) contrast(95%); }
.color-warning { filter: brightness(0) saturate(100%) invert(68%) sepia(73%) saturate(596%) hue-rotate(354deg) brightness(105%) contrast(101%); }
.color-info { filter: brightness(0) saturate(100%) invert(51%) sepia(59%) saturate(588%) hue-rotate(140deg) brightness(92%) contrast(94%); }
.color-light { filter: brightness(0) saturate(100%) invert(91%) sepia(4%) saturate(576%) hue-rotate(200deg) brightness(109%) contrast(96%); }
.color-dark { filter: brightness(0) saturate(100%) invert(18%) sepia(6%) saturate(1291%) hue-rotate(169deg) brightness(89%) contrast(82%); }
.color-body { filter: brightness(0) saturate(100%) invert(12%) sepia(9%) saturate(824%) hue-rotate(169deg) brightness(94%) contrast(91%); }
.color-muted { filter: brightness(0) saturate(100%) invert(50%) sepia(2%) saturate(2378%) hue-rotate(168deg) brightness(89%) contrast(84%); }
.color-white { filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(54deg) brightness(109%) contrast(101%); }
.color-disabled { filter: brightness(0) saturate(100%) invert(81%) sepia(2%) saturate(180%) hue-rotate(335deg) brightness(108%) contrast(96%); }
.color-disabled-dark { filter: brightness(0) saturate(100%) invert(91%) sepia(0%) saturate(0%) hue-rotate(185deg) brightness(95%) contrast(84%); }
.color-alt-default { filter: brightness(0) saturate(100%) invert(46%) sepia(70%) saturate(3992%) hue-rotate(193deg) brightness(99%) contrast(90%); }
.color-alt-warning { filter: brightness(0) saturate(100%) invert(59%) sepia(94%) saturate(289%) hue-rotate(341deg) brightness(98%) contrast(99%); }
.color-alt-danger { filter: brightness(0) saturate(100%) invert(37%) sepia(84%) saturate(805%) hue-rotate(308deg) brightness(99%) contrast(90%); }
.color-alt-success { filter: brightness(0) saturate(100%) invert(80%) sepia(11%) saturate(2532%) hue-rotate(99deg) brightness(87%) contrast(89%); }
.color-se-primary { filter: brightness(0) saturate(100%) invert(67%) sepia(14%) saturate(2047%) hue-rotate(79deg) brightness(97%) contrast(90%); }
.color-se-link { filter: brightness(0) saturate(100%) invert(71%) sepia(47%) saturate(2213%) hue-rotate(167deg) brightness(93%) contrast(94%); }
.color-se-link-dark { filter: brightness(0) saturate(100%) invert(42%) sepia(23%) saturate(1361%) hue-rotate(166deg) brightness(98%) contrast(93%); }
.color-se-danger { filter: brightness(0) saturate(100%) invert(14%) sepia(84%) saturate(3919%) hue-rotate(325deg) brightness(79%) contrast(112%); }
.color-se-secondary { filter: brightness(0) saturate(100%) invert(71%) sepia(5%) saturate(181%) hue-rotate(191deg) brightness(91%) contrast(86%); }
.color-se-warning { filter: brightness(0) saturate(100%) invert(76%) sepia(49%) saturate(7416%) hue-rotate(7deg) brightness(95%) contrast(101%); }
.color-se-warning-light { filter: brightness(0) saturate(100%) invert(85%) sepia(31%) saturate(3980%) hue-rotate(359deg) brightness(103%) contrast(106%); }
.color-sf-highlight { filter: brightness(0) saturate(100%) invert(23%) sepia(97%) saturate(3081%) hue-rotate(195deg) brightness(96%) contrast(101%); }
.color-sf-primary { filter: brightness(0) saturate(100%) invert(26%) sepia(75%) saturate(2018%) hue-rotate(191deg) brightness(99%) contrast(102%); }
.color-sf-primary-dark { filter: brightness(0) saturate(100%) invert(10%) sepia(97%) saturate(4590%) hue-rotate(200deg) brightness(90%) contrast(102%); }

.color-primary-light { filter: brightness(0) saturate(100%) invert(33%) sepia(82%) saturate(3926%) hue-rotate(193deg) brightness(100%) contrast(101%); }
.color-primary-dark { filter: brightness(0) saturate(100%) invert(21%) sepia(67%) saturate(5693%) hue-rotate(196deg) brightness(85%) contrast(101%); }
.color-secondary-light { filter: brightness(0) saturate(100%) invert(56%) sepia(14%) saturate(260%) hue-rotate(169deg) brightness(90%) contrast(90%); }
.color-secondary-dark { filter: brightness(0) saturate(100%) invert(41%) sepia(5%) saturate(796%) hue-rotate(166deg) brightness(92%) contrast(90%); }
.color-success-light { filter: brightness(0) saturate(100%) invert(71%) sepia(10%) saturate(2988%) hue-rotate(80deg) brightness(84%) contrast(94%); }
.color-success-dark { filter: brightness(0) saturate(100%) invert(39%) sepia(90%) saturate(416%) hue-rotate(82deg) brightness(95%) contrast(88%); }
.color-danger-light { filter: brightness(0) saturate(100%) invert(56%) sepia(45%) saturate(5329%) hue-rotate(322deg) brightness(89%) contrast(96%); }
.color-danger-dark { filter: brightness(0) saturate(100%) invert(16%) sepia(80%) saturate(4275%) hue-rotate(345deg) brightness(90%) contrast(85%); }
.color-warning-light { filter: brightness(0) saturate(100%) invert(95%) sepia(47%) saturate(2254%) hue-rotate(329deg) brightness(99%) contrast(102%); }
.color-warning-dark { filter: brightness(0) saturate(100%) invert(60%) sepia(98%) saturate(1494%) hue-rotate(10deg) brightness(103%) contrast(101%); }
.color-info-light { filter: brightness(0) saturate(100%) invert(64%) sepia(88%) saturate(916%) hue-rotate(142deg) brightness(88%) contrast(87%); }
.color-info-dark { filter: brightness(0) saturate(100%) invert(43%) sepia(87%) saturate(490%) hue-rotate(140deg) brightness(86%) contrast(85%); }
.color-muted-light { filter: brightness(0) saturate(100%) invert(56%) sepia(10%) saturate(370%) hue-rotate(169deg) brightness(89%) contrast(85%); }
.color-muted-dark { filter: brightness(0) saturate(100%) invert(38%) sepia(18%) saturate(224%) hue-rotate(166deg) brightness(95%) contrast(88%); }
.color-alt-warning-light { filter: brightness(0) saturate(100%) invert(80%) sepia(36%) saturate(783%) hue-rotate(321deg) brightness(99%) contrast(99%); }
.color-alt-warning-dark { filter: brightness(0) saturate(100%) invert(70%) sepia(17%) saturate(3850%) hue-rotate(332deg) brightness(100%) contrast(96%); }
.color-alt-default-light { filter: brightness(0) saturate(100%) invert(71%) sepia(91%) saturate(4393%) hue-rotate(189deg) brightness(96%) contrast(97%); }
.color-alt-default-dark { filter: brightness(0) saturate(100%) invert(32%) sepia(56%) saturate(3175%) hue-rotate(196deg) brightness(94%) contrast(89%); }
.color-alt-danger-light { filter: brightness(0) saturate(100%) invert(62%) sepia(44%) saturate(3309%) hue-rotate(311deg) brightness(103%) contrast(111%); }
.color-alt-danger-dark { filter: brightness(0) saturate(100%) invert(32%) sepia(51%) saturate(3649%) hue-rotate(329deg) brightness(94%) contrast(97%); }
.color-alt-success-light { filter: brightness(0) saturate(100%) invert(77%) sepia(87%) saturate(336%) hue-rotate(90deg) brightness(90%) contrast(87%); }
.color-alt-success-dark { filter: brightness(0) saturate(100%) invert(53%) sepia(76%) saturate(371%) hue-rotate(106deg) brightness(97%) contrast(99%); }
.color-se-secondary-light { filter: brightness(0) saturate(100%) invert(77%) sepia(9%) saturate(72%) hue-rotate(187deg) brightness(94%) contrast(87%); }
