$slick-filled-filter-color:                                 $slick-form-control-focus-border-color !default;
$slick-filled-filter-border:                                $slick-form-control-border !default;
$slick-filled-filter-box-shadow:                            $slick-form-control-focus-border-color !default;
$slick-filled-filter-font-weight:                           400 !default;

// ----------------------------------------------
// applied stylinh when Filters are filled in
// ----------------------------------------------

.slick-headerrow {
  input.search-filter.filled,
    .search-filter.filled input,
    .search-filter.filled input.flatpickr-input,
    .search-filter.filled .input-group-addon.slider-value,
    .search-filter.filled .input-group-addon select {
      color: var(--slick-filled-filter-color, $slick-filled-filter-color);
      font-weight: var(--slick-filled-filter-font-weight, $slick-filled-filter-font-weight);
      border: var(--slick-filled-filter-border, $slick-filled-filter-border);
      box-shadow: var(--slick-filled-filter-box-shadow, $slick-filled-filter-box-shadow);
    }
    .search-filter.filled .input-group-addon select {
      border-right: 0px;
    }
    .search-filter.filled {
      .ms-choice {
        box-shadow: var(--slick-filled-filter-box-shadow, $slick-filled-filter-box-shadow);
        border: var(--slick-filled-filter-border, $slick-filled-filter-border);
        span {
          font-weight: var(--slick-filled-filter-font-weight, $slick-filled-filter-font-weight);
          color: var(--slick-filled-filter-color, $slick-filled-filter-color);
        }
      }
    }
}